import { Injectable } from '@angular/core';
import { Title, Meta } from '@angular/platform-browser';

@Injectable({
  providedIn: 'root'
})
export class MetaService {
  constructor(private titleService: Title, private meta: Meta) { }

  metaData(page: any = "", title: any, meta: any) {

    /* Default meta data - need to change here as well on index.html to reflect on the landing page.*/
    
    let titlemain = title ? title : "Intercell | Career Mentorship | Career Guidance| Home |";
    let description = "Get 1-1 Exclusive Career Mentorship through World's best Mentors from 5000+ Brands. More than 250 Career Specialisations."
    let metaData: any = {
      'title': "Intercell | Career Mentorship | Career Guidance| Home |",
      'type': "website",
      'site_name': "IntercellWorld"
    };

    /* select meta according page */
    switch (page) {
      case 'contact': /* contact page */
        this.titleService.setTitle('Intercell | Career Mentorship | Career Guidance | Contact Us |');
        metaData.title = "Contact Us";
        metaData.url = "https://www.intercellworld.com/contact";  
        metaData.image = "https://www.intercellworld.com/assets/images/social_logo_about.jpg";  
        metaData.type = "image/jpg";
        metaData.width =  "300";
        metaData.height =  "200"; 
        break;
		case 'college': /* contact page */
        this.titleService.setTitle('Intercell | Career Mentorship | Career Guidance | College & Universities|');
        metaData.title = "College & Universities";
        metaData.url = "https://www.intercellworld.com/colleges-universities";  
        metaData.image = "https://www.intercellworld.com/assets/images/social_logo_about.jpg";  
        metaData.type = "image/jpg";
        metaData.width =  "300";
        metaData.height =  "200"; 
        break;
		case 'corporate': /* contact page */
        this.titleService.setTitle('Intercell | Career Mentorship | Career Guidance | Corporates|');
        metaData.title = "Corporates";
        metaData.url = "https://www.intercellworld.com/corporates";  
        metaData.image = "https://www.intercellworld.com/assets/images/social_logo_about.jpg";  
        metaData.type = "image/jpg";
        metaData.width =  "300";
        metaData.height =  "200"; 
        break;
		case 'menteeterms': /* contact page */
        this.titleService.setTitle('Intercell | Career Mentorship | Career Guidance | Mentee Terms|');
        metaData.title = "Mentee Terms";
        metaData.url = "https://www.intercellworld.com/term/mentee-terms";  
        metaData.image = "https://www.intercellworld.com/assets/images/social_logo_about.jpg";  
        metaData.type = "image/jpg";
        metaData.width =  "300";
        metaData.height =  "200"; 
        break;
		case 'mentorterms': /* contact page */
        this.titleService.setTitle('Intercell | Career Mentorship | Career Guidance | Intercell | Career Mentorship | Career Guidance | Mentor Terms|');
        metaData.title = "Mentor Terms";
        metaData.url = "https://www.intercellworld.com/term/mentor-terms";  
        metaData.image = "https://www.intercellworld.com/assets/images/social_logo_about.jpg";  
        metaData.type = "image/jpg";
        metaData.width =  "300";
        metaData.height =  "200"; 
        break;
		case 'mentorabout': /* contact page */
        this.titleService.setTitle('Intercell | Career Mentorship | Career Guidance | Mentor About|');
        metaData.title = "Mentor About";
        metaData.url = "https://www.intercellworld.com/term/about-mentor";  
        metaData.image = "https://www.intercellworld.com/assets/images/social_logo_about.jpg";  
        metaData.type = "image/jpg";
        metaData.width =  "300";
        metaData.height =  "200"; 
        break;
		case 'menteeabout': /* contact page */
        this.titleService.setTitle('Intercell | Career Mentorship | Career Guidance | About|');
        metaData.title = "About";
       /// metaData.url = "https://www.intercellworld.com/term/mabout-mentee";  
        metaData.image = "https://www.intercellworld.com/assets/images/social_logo_about.jpg";  
        metaData.type = "image/jpg"; 
        metaData.width =  "300";
        metaData.height =  "200"; 
        break;
      case 'Reset Password': /* reset-password */
        this.titleService.setTitle('Intercell | Career Mentorship | Career Guidance | Reset Password |');
        metaData.title = "Reset Password";
        metaData.url = "https://www.intercellworld.com/reset-password";  
        metaData.image = "https://www.intercellworld.com/assets/images/social_logo_about.jpg";  
        metaData.type = "image/jpg";
        metaData.width =  "300";
        metaData.height =  "200";   
        break;
      case 'faq': /* faq page */
        this.titleService.setTitle('Intercell | Career Mentorship | Career Guidance | FAQ |');
        metaData.title = 'FAQ |';
        metaData.url = "https://www.intercellworld.com/faq";  
        metaData.image = "https://www.intercellworld.com/assets/images/social_logo_about.jpg";  
        metaData.type = "image/jpg";
        metaData.width =  "300";
        metaData.height =  "200";
        break;
        case 'Press Release': /* faq page */
        this.titleService.setTitle('Intercell | Career Mentorship | Career Guidance | Intercell | Career Mentorship | Career Guidance | Press Release |');
        metaData.title = 'Press Release |';
     
        break;
     case 'referral': /* Referral page */
        this.titleService.setTitle('Intercell | Career Mentorship | Career Guidance | Referral |');
        metaData.title = 'Referral |';
        metaData.image = "https://www.intercellworld.com/assets/images/social_logo.jpg";  
        break;
     case 'booking': /* Referral page */
        this.titleService.setTitle('Intercell | Career Mentorship | Career Guidance | Speak to a Mentor |');
        metaData.title = 'Speak to a Mentor |';
        break;   
      case 'dashboard1': /* Referral page */
        this.titleService.setTitle('Intercell | Career Mentorship | Career Guidance | Dashboard |');
        metaData.title = 'Dashboard |';
        break;
      case 'profiles': /* Referral page */
        this.titleService.setTitle('Intercell | Career Mentorship | Career Guidance | Profile |');
        metaData.title = 'Profile |';
        break;  
      case 'need': /* Referral page */
        this.titleService.setTitle('Intercell | Career Mentorship | Career Guidance | Need Help |');
        metaData.title = 'Need Help |';
        break; 
     case 'setavailability': /* Referral page */
        this.titleService.setTitle('Intercell | Career Mentorship | Career Guidance | Set Availability |');
        metaData.title = 'Set Availability |';
        break;     
     case 'notification': /* Referral page */
        this.titleService.setTitle('Intercell | Career Mentorship | Career Guidance | Notification |');
        metaData.title = 'Notification |';
        break;  
      case 'upcomming': /* Referral page */
        this.titleService.setTitle('Intercell | Career Mentorship | Career Guidance | Upcoming Session |');
        metaData.title = 'Upcoming Session |';
        break;    
     case 'setting': /* Referral page */
        this.titleService.setTitle('Intercell | Career Mentorship | Career Guidance | Setting |');
        metaData.title = 'Setting |';
        metaData.image = "https://www.intercellworld.com/assets/images/social_logo.jpg";  
        break;
    case 'exclusive': /* Exclusive page */
        this.titleService.setTitle('Intercell | Career Mentorship | Career Guidance | Exclusive |');
        metaData.title = 'Exclusive |';
        metaData.image = "https://www.intercellworld.com/assets/images/social_logo.jpg";  
        break;

    case 'handbook': /* Referral page */
        this.titleService.setTitle('Intercell | Career Mentorship | Career Guidance | Setting |');
        metaData.title = 'Setting |';
        metaData.image = "https://www.intercellworld.com/assets/images/social_logo.jpg";  
        break;        
     case 'history': /* Referral page */
        this.titleService.setTitle('Intercell | Career Mentorship | Career Guidance | Session History |');
        metaData.title = 'Session History |';
        metaData.image = "https://www.intercellworld.com/assets/images/social_logo.jpg";  
        break;    
      case 'story': /* story page */
        metaData.title = 'Intercell | Career Mentorship | Career Guidance | Story |';
        metaData.url = "https://www.intercellworld.com/brand-story";  
        metaData.image = "https://www.intercellworld.com/assets/images/social_logo_about.jpg";  
        metaData.type = "image/jpg";
        metaData.width =  "300";
        metaData.height =  "200";
        this.titleService.setTitle(metaData.title);
        break;
      case 'field': /* field page */
        this.titleService.setTitle(titlemain);
        metaData.title = meta.title;
        metaData.description = meta.description;
        metaData.keyword = meta.keyword;
        metaData.image = meta.image;      
        break;  
      case 'profile': /* faq page */
        this.titleService.setTitle(titlemain);
        metaData.title = meta.title;
        metaData.description = meta.description;
        metaData.image = meta.image;      
        break;
      case 'signin': 
         metaData.title = 'Intercell | Career Mentorship | Career Guidance | Story |';
        metaData.url = "https://www.intercellworld.com/brand-story";  
        metaData.image = "https://www.intercellworld.com/assets/images/social_logo_about.jpg";  
        metaData.type = "image/jpg";
        metaData.width =  "300";
        metaData.height =  "200";
        break;
      case 'mentorRecommendation':
        this.titleService.setTitle('Intercell | Career Mentorship | Career Guidance | Mentor Recommendation |');
        metaData.title = 'Mentor Recommendation |';
        break;
      default:
        this.titleService.setTitle(titlemain);
        metaData.title = titlemain;
        metaData.description = description;
        metaData.image = "https://www.intercellworld.com/assets/images/social_logo.jpg";      
        break;
    }

   
        /* update meta in html */
     for (var key in metaData) {
          this.meta.updateTag({ name: key, content: metaData[key].toString() });
          this.meta.updateTag({ property: "og:" + key, content: metaData[key].toString() });
      }
    
    return metaData;
  }
}
